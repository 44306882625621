<template>
  <div>
    <navBar :title="title"></navBar>

    <ul class="express">
      <li :key="index" v-for="(item,index) in datas">{{item.info}}</li>
    </ul>
  </div>
</template>

<script>
import navBar from './navBar';
export default {
  name: '',
  components: {
    navBar,
  },
  data () {
    return {
      msg: '',
      title: {
        name: '快递信息',
        status: true,
      },
      datas: []
    }
  },
  created () {
    console.log('90haha', this.$route.query);
    this.datas = this.$route.query
  },
  computed: {
  },
  methods: {

  }
}
</script>


<style lang="less" scoped>
ul {
  padding: 20px 10px;
  li {
    margin-bottom: 10px;
  }
}
</style>

